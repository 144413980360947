<template>
  <div class="home">
    <Stepper>
      <Step
        title="Choose Project Period"
        :condition="getProjectData.projectPeriod.end"
        :step="1"
      >
        <ChooseProjectPeriod
          :project-period="getProjectData.projectPeriod"
        ></ChooseProjectPeriod>
      </Step>
      <Step
        title="Create Project Information"
        :step="2"
        :condition="getProjectData.title && getProjectData.image"
      >
        <CreateProjectInformation
            :project="getProjectData"
        ></CreateProjectInformation>
      </Step>
      <Step
          title="Create Vacancies"
          :step="3"
          :condition="getProjectData.vacancies.length > 0"
      >
        <CreateVacancies
            :vacancies="getProjectData.vacancies"
        ></CreateVacancies>
      </Step>
      <Step :loading="creatingProject" @finished="createProject" :buttonNextLabel="$t('button.publish')" title="Publish Project" :step="4">
        <ViewProject
            style="width: 60vw"
            :project="getProjectData"
        ></ViewProject>
      </Step>
    </Stepper>
  </div>
</template>

<script>
import Stepper from "@/components/Stepper/Stepper";
import Step from "@/components/Stepper/components/Step";
import ChooseProjectPeriod from "@/components/CreateProject/ChooseProjectPeriod";
import CreateVacancies from "@/components/CreateProject/CreateVacancies/CreateVacancies";
import { mapGetters, mapActions } from "vuex";
import CreateProjectInformation from "./CreateProjectInformation";
import ViewProject from "../ViewProject/ViewProject";
import project_mw from "../../middleware/project_mw";
import EventBus from "../../../event-bus";

export default {
  name: "FindEmployeesView",
  components: {
    ViewProject,
    CreateProjectInformation,
    CreateVacancies,
    ChooseProjectPeriod,
    Step,
    Stepper
  },
  data() {
    return {
      creatingProject: false,
    };
  },
  computed: {
    ...mapGetters(["getProjectData", "getCurrentUser"]),
  },
  mounted() {
    this.getProjectData.owner = this.getCurrentUser
  },
  methods: {
    ...mapActions(["resetProjectData"]),
    async createProject() {
      this.creatingProject = true
      const response = await project_mw.postProject(this.getProjectData).then(() => {
        this.resetProjectData()
        this.creatingProject = false
        EventBus.$emit("SUCCESS", "Project has been successfully created")
        this.$router.push({name: 'YourProjects'})
      }).catch(error => {
        EventBus.$emit("ERROR", error)
      })

      console.log( response)
    }
  }
};
</script>

<style lang="scss"></style>
