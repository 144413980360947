<template>
  <v-card class="project-info-card">
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <div class="image-upload">
            <v-img class="image" :src="this.project.image || require('@/assets/images/placeholder.png')"></v-img>
            <v-file-input
                class="image-input"
                hide-details
                @change="createImage"
                placeholder="Project Image*"
                outlined
                prepend-icon="mdi-camera"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </div>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
             <v-text-field v-model="project.title" hide-details filled label="Project Title*"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="project.customer" hide-details filled label="Customer"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field disabled :value="getCurrentUserName" hide-details filled label="Project Owner"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                  hide-details
                  v-model="project.description"
                  auto-grow
                  filled
                  rows="12"
                  label="Project Description"
                  class="project-description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CreateProjectInformation",
  data () {
    return {
      files: [],
      image: '',
      title: null
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters(["getCurrentUserName"])
  },
  methods: {
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.project.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
  }
};
</script>

<style scoped lang="scss">
.project-info-card {
  position: relative;
  width: 70%;
  height: fit-content;
  background: $white;
}
.image-upload {
  border-radius: 5px;
  position: relative;
  height: 100%;
  padding: 20px;
  width: 100%;
  background: #e1e1e1;

  .image {
    height: calc(100% - 80px);
  }

  .image-input {
    padding-top: 24px;
    width: 100%;
  }
}

.project-description {
  height: 100%;
}
</style>
