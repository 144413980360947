import { render, staticRenderFns } from "./VacancyCard.vue?vue&type=template&id=3bfbbeec&scoped=true&"
import script from "./VacancyCard.vue?vue&type=script&lang=js&"
export * from "./VacancyCard.vue?vue&type=script&lang=js&"
import style0 from "./VacancyCard.vue?vue&type=style&index=0&id=3bfbbeec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bfbbeec",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VBtn,VCard,VCardActions,VCardSubtitle,VCardText,VCardTitle,VChip,VHover,VIcon,VImg,VResponsive,VRow,VSpacer,VSpeedDial})
