<template>
  <div class="grid">
    <v-row class="vacancy-cards" justify="center">
      <v-col
        v-for="(vacancy, index) in vacancies"
        :key="index"
        xl="3"
        lg="4"
        md="6"
        sm="12"
        xs="12"
      >
        <VacancyCard :deactivated="true" :vacancy="vacancy"></VacancyCard>
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="12" xs="12">
        <VacancyCard :create-new="true"></VacancyCard>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VacancyCard from "@/components/CreateProject/CreateVacancies/components/VacancyCard";
export default {
  name: "CreateVacancies",
  components: { VacancyCard },
  props: {
    vacancies: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.grid {
  min-width: 100%;
}

.vacancy-cards {
}
</style>
