<template>
  <v-card class="project-card">
    <v-card-title class="headline-img-container pb-6" v-if="!abstract">
      <v-img class="project-image__fullwidth" :src="project.image">
        <div class="title-wrapper">
          <div></div>
          <div class="project-title__fullwidth">
            <h2 class="project-customer__fullwidth text-light pb-2">{{project.customer}}</h2>
            <h1 class="project-title pb-2" @click="openProject()">{{project.title}}</h1>
            <h4 class="text-regular">{{project.projectPeriod.start + " - " + project.projectPeriod.end}}</h4>
          </div>
        </div>
      </v-img>
    </v-card-title>
    <v-card-text>
      <div v-if="project.application && abstract" class="applied-overlay">
        <div class="vertically-centered">
          <v-btn @click="openProject" color="success" x-large outlined>VIEW APPLICATION</v-btn><br>
          <v-icon>mdi-check</v-icon>
        </div>
      </div>
      <v-row v-if="abstract">
        <v-col cols="5 pt-0">
          <v-img class="project-image" :src="project.image"></v-img>
        </v-col>
        <v-col cols="7">
          <h2 class="text-light pt-6 pb-3">{{project.customer}}</h2>
          <h1 :class="abstract ? 'project-title' : 'text-bold'" @click="openProject()">{{project.title}}</h1>
          <h4 class="text-regular pt-3">{{project.projectPeriod.start + " - " + project.projectPeriod.end}}</h4>
        </v-col>
        <div v-if="isOwner" class="applicants-counter">
          <v-icon>mdi-account-group</v-icon>
          {{applications.length + " Applications"}}
          <v-avatar class="ml-3" color="dsilver" size="60" :key="id" v-for="(applicant, id) in applications.slice(0, 3)">
            <v-img :src="applicant || require('@/assets/images/no-profile-dark.jpg')"></v-img>
          </v-avatar>
          <v-avatar class="ml-3" color="msilver" size="40" v-if="applications.length > 3">
            {{applications.length - 3 }}..
          </v-avatar>
        </div>
      </v-row>
      <ExpansionTitle :deactivated="abstract" title="Description">
        <p class="pt-3">
          {{project.description}}
        </p>
      </ExpansionTitle>
      <ExpansionTitle title="Project Owner" v-if="!abstract" :open="!isOwner">
        <p class="pt-3">
          <v-avatar size="56">
            <img
                alt="user"
                class=""
                :src="project.owner.userProfile.imageURL || require('@/assets/images/no-profile-dark.jpg')"
            >
          </v-avatar>
          <span class="project-owner">{{project.owner.displayName}}</span>
        </p>
      </ExpansionTitle>
      <ExpansionTitle title="Vacancies" v-if="!abstract" :open="!isOwner">
        <v-row class="pt-10" justify="start">
          <v-col cols="6" :key="vacancy.id" v-for="vacancy in this.project.vacancies">
            <VacancyCard :deactivated="isOwner" :application="project.application" @applied="applied" :read-only="true" :projectUUID="project.uuid" :vacancy="vacancy"></VacancyCard>
          </v-col>
        </v-row>
      </ExpansionTitle>
      <ExpansionTitle title="Applications" v-if="isOwner && !abstract">
        <ChooseTeam class="pt-3" :project-data="project"></ChooseTeam>
      </ExpansionTitle>
      <v-dialog overlay-opacity=".8" width="50%" v-model="readFullProject">
        <ViewProject :project="project" :abstract="false"></ViewProject>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import VacancyCard from "../CreateProject/CreateVacancies/components/VacancyCard";
import {mapGetters} from "vuex";
import application_mw from "../../middleware/application_mw";
import ChooseTeam from "../CreateProject/ChooseTeam/ChooseTeam";
import ExpansionTitle from "../core/ExpansionTitle";
export default {
  name: "ViewProject",
  components: {ExpansionTitle, VacancyCard,ChooseTeam},
  data() {
    return {
      readFullProject: false,
      applications: [],
      projectOwner: ''
    }
  },
  computed: {
    ...mapGetters(["getCurrentUserUUID"]),
    isOwner() {
      return this.project.uuid && this.project.owner.uuid === this.getCurrentUserUUID
    }
  },
  props: {
    project: {
      type: Object,
      required: true
    },
    abstract: {
      default: false
    }
  },
  created() {
    if (this.isOwner) this.loadApplications()
  },
  methods: {
    openProject() {
      if (this.abstract) this.readFullProject=true
    },
    applied(application) {
      this.project.application = application
    },
    async loadApplications() {
      this.applications = await application_mw.getApplicantsImagesForProject(this.project.uuid)
    }
  }
}
</script>

<style scoped lang="scss">

.project-title {
  font-weight: $font-weight-bold;
  cursor: pointer;

  &__fullwidth {
    color: $white;
    width: 100%;
    padding: 32px 32px 32px 32px;
    pointer-events: none;
    background-image:
        radial-gradient(
                circle at bottom left,
                rgb(79, 68, 75, 0.75),
                rgb(79, 68, 75, 0) 80%
        );
  }
}

.project-title:hover {
  color: $accent;
}

.project-card {
  overflow: hidden;
  min-width: 500px;
  margin: 0;
  border-radius: 5px !important;
  background: $white;
}

.headline-img-container {
  width: calc(100% + 64px);
  margin: -16px -32px -16px;
}
.project-image {
  margin: -4px 0 0 -16px;
  border-top-left-radius: $border-radius-root;
  border-bottom-right-radius: $border-radius-root;
  height: 300px;

  &__fullwidth {
    border-top-left-radius: $border-radius-root;
    border-top-right-radius: $border-radius-root;
    height: 400px;
  }

}

.project-owner {
  margin-left: 12px;
  font-weight: $font-weight-light;
  font-size: $font-size-big;
}


.title-wrapper {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-items: end;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.applied-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  background: rgba(62, 62, 62, 0.8);
  z-index: 1;
  text-align: center;
  color: $success;

  .v-icon {
    color: $success;
  }
}

.vertically-centered {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.applicants-counter {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>