import axios from "axios";
import {endpoint} from "../../config";
import store from "../store";
import application_mw from "./application_mw";
import user_mw from "./user_mw";
import EventBus from "../../event-bus";

const url = endpoint + 'projects/'

// const accessToken = msal.default().data.accessToken



function getConfig() {
    const idToken = store.getters.getTokenID

    return {
        headers: { Authorization: `Bearer ${idToken}` }
    };
}

function getUserProfile() {
    return store.state.account.profile
}


export default {

    async postProject(project) {
        const userProfile = getUserProfile()

        const projectObject = {
            startDate: project.projectPeriod.start,
            endDate: project.projectPeriod.end,
            name: project.title,
            customer: project.customer,
            owner: userProfile.uuid,
            description: project.description,
            vacancies: project.vacancies.map(vacancy => {
                return {
                    role: vacancy.role,
                    specialization: vacancy.role_field || "",
                    startDate: vacancy.startDate,
                    endDate: vacancy.endDate,
                    workingHours: vacancy.workingHours,
                    skills: vacancy.skills,
                    country: vacancy.country,
                    office: vacancy.office
                }
            }),
            image: project.image,
        }

        return axios.post(url, projectObject, getConfig())
    },

    getAllProjects() {

        return new Promise((resolve, reject) => {
            axios.get(url, getConfig()).then(async (response) => {
                const res = await response.data
                const projects = await Promise.all(res.map(async project => {
                    return {
                        uuid: project.uuid,
                        projectPeriod: {
                            start: project.startDate,
                            end: project.endDate
                        },
                        title: project.name,
                        customer: project.customer,
                        owner: await user_mw.getUser(project.owner),
                        description: project.description,
                        vacancies: project.vacancies.map(vacancy => {
                            return {
                                uuid: vacancy.uuid,
                                role: vacancy.role,
                                role_field: vacancy.specialization || "",
                                startDate: vacancy.startDate,
                                endDate: vacancy.endDate,
                                workingHours: vacancy.workingHours,
                                skills: vacancy.skills,
                                country: vacancy.country,
                                office: vacancy.office
                            }
                        }),
                        image: project.image,
                        application: await application_mw.ownApplicationForProject(project.uuid),
                    };

                }))
                console.log(projects)
                resolve(projects)
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                console.log(error)
                reject(error)
            })
        })
    },

    getProjectsByUserUUID() {
        const userProfile = getUserProfile()
        return new Promise((resolve, reject) => {

            const path = url + "user/" + userProfile.uuid

            axios.get(path, getConfig()).then(async (response) => {
                const res = await response.data
                const projects = await Promise.all(res.map(async project => {
                    return {
                        uuid: project.uuid,
                        projectPeriod: {
                            start: project.startDate,
                            end: project.endDate
                        },
                        title: project.name,
                        customer: project.customer,
                        owner: await user_mw.getUser(project.owner),
                        description: project.description,
                        vacancies: project.vacancies.map(vacancy => {
                            return {
                                uuid: vacancy.uuid,
                                role: vacancy.role,
                                role_field: vacancy.specialization || "",
                                startDate: vacancy.startDate,
                                endDate: vacancy.endDate,
                                workingHours: vacancy.workingHours,
                                skills: vacancy.skills,
                                country: vacancy.country,
                                office: vacancy.office
                            }
                        }),
                        image: project.image,
                        application: "",
                    };

                }))
                console.log(projects)
                resolve(projects)
            }).catch(error => {
                console.log(error)
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    }
}