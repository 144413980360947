<template>
  <div>
    <v-card class="choose-member-card" min-width="366" color="white">
      <v-row>
        <v-col class=" pa-0" md="4">
          <VacancyCard
            :employee="selectedEmployee"
            :vacancy="vacancy"
            :deactivated="true"
          ></VacancyCard>
        </v-col>
        <v-col class="pa-0" md="8">
          <v-list v-if="appliedEmployees.length > 0" class="user-list pr-5">
            <v-list-item
              @click="selectedEmployee = employee"
              :class="[
                isActive(employee)
                  ? 'user-list-item selected-user-list-item'
                  : 'user-list-item'
              ]"
              color="white"
              v-for="employee in appliedEmployees"
              :key="employee.uuid"
            >
              <v-list-item-avatar>
                <v-img :src="employee.userProfile.imageURL || require('@/assets/images/no-profile-dark.jpg')"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ employee.displayName }}
                </v-list-item-title>
                <v-list-item-subtitle>
<!--                  {{-->
<!--                    $t("countries." + employee.location.country) +-->
<!--                      ", " +-->
<!--                      $t("offices." + employee.location.office)-->
<!--                  }}-->
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  @click="openValtechProfiles(employee.userProfile.profileLink)"
                  color="dgray"
                  class="view-profile-btn"
                  outlined
                  x-small
                  >View Profile</v-btn
                >
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <div class="center-all" v-else>
            <div class="no-vacancies">
              NO VACANCIES
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import VacancyCard from "../../CreateVacancies/components/VacancyCard";
import { mapMutations } from "vuex";
import application_mw from "../../../../middleware/application_mw";
import EventBus from "../../../../../event-bus";

export default {
  name: "ChooseProjectMember",
  components: { VacancyCard },
  data() {
    return {
      appliedEmployees: [],
      selectedEmployee: {},
      dialog: false
    };
  },
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  },
  watch: {
    selectedEmployee(newVal) {
      this.vacancy.designated_employee = newVal.uuid;
      this.editVacancy(this.vacancy);
    }
  },
  created() {
    this.load();
  },
  methods: {
    ...mapMutations(["editVacancy"]),
    async load() {
      console.log(this.vacancy.uuid)
      this.appliedEmployees = await application_mw.getApplicationsForVacancy(this.vacancy.uuid);
    },
    isActive(employee) {
      return this.selectedEmployee.uuid === employee.uuid;
    },
    openValtechProfiles(url) {
      if (url) {
        window.open(url,'ValtechProfiles','resizable,height=560,width=960'); return false;
      } else {
        EventBus.$emit("ERROR", "User has no profile set")
      }
    }
  }
};
</script>

<style scoped lang="scss">
.choose-member-card {
  margin: 15px;
}

.user-list {
  overflow-y: auto;
  height: 100%;
  margin: 0 20px 0 20px;
  background: $white;
}

.user-list-item {
  cursor: pointer;
  border-radius: 15px;
}

.selected-user-list-item {
  margin-left: 6px;
  background-color: $msilver;
}

.user-list-item:hover {
  background: $dsilver;
}

.view-profile-btn {
  border-radius: 15px;
}

.profile-dialog {
  width: 100%;
  height: 80vh;
}

.no-vacancies {
  font-size: 36px;
  font-weight: $font-weight-bold;
  color: $msilver;
}
</style>
