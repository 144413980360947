<template>
  <div>
    <v-card
      color="white"
      v-if="!edit && !createNew"
      class="mx-auto"
      elevation="20"
    >
      <v-hover v-slot="{ hover }">
        <v-responsive>
          <div
            v-if="employee && employee.displayName || isApplied"
            class="employee-name-container"
          >
            <div class="employee-name">
              {{isApplied ? getCurrentUser.displayName : employee.displayName }}
            </div>
          </div>
          <v-img
            class="white--text align-end"
            height="286"
            :src=" isApplied ? getCurrentUser.userProfile.imageURL :
              (employee && employee.userProfile && employee.userProfile.imageURL
                ? employee.userProfile.imageURL
                : require('@/assets/images/no-profile-dark.jpg'))
            "
          >
            <v-card-title>
              <span class="sub-title">{{
                $t("vacancy.roles." + vacancy.role)
              }}</span>
              <v-spacer></v-spacer>
              <span class="sub-title">{{
                vacancy.role_field
                  ? $t("vacancy.fields." + vacancy.role_field)
                  : ""
              }}</span>
            </v-card-title>
          </v-img>

          <v-card-subtitle class="pb-0">
            {{ employeesOrigin }}
          </v-card-subtitle>

          <v-card-text>
            <div v-if="displayEmployeeOnly">
              <v-row class="pt-5 ml-0">
                <v-icon>mdi-phone</v-icon>
                <span class="contact-text">{{ employee.phone }}</span>
              </v-row>
              <v-row class="pt-3 pb-3 ml-0">
                <v-icon>mdi-email</v-icon>
                <span class="contact-text">{{ employee.email }}</span>
              </v-row>
            </div>
            <div v-else class="text--primary mt-3 skills-container">
              <v-chip
                :key="id"
                v-for="(skill, id) in vacancy.skills"
                class="mr-3 mb-3"
                color="grey"
                >{{ skill }}</v-chip
              >
<!--              <div v-if="employee && employee.uuid">-->
<!--                <v-chip-->
<!--                  :key="id"-->
<!--                  v-for="(skill, id) in employee.skills.filter(-->
<!--                    val => !vacancy.skills.includes(val)-->
<!--                  )"-->
<!--                  class="mr-3 mb-3"-->
<!--                  color="success"-->
<!--                >-->
<!--                  {{ skill }}-->
<!--                </v-chip>-->
<!--              </div>-->
            </div>
          </v-card-text>
          <v-speed-dial
            v-if="!readOnly && hover && !employee"
            v-model="fab"
            top
            right
            absolute
            direction="left"
          >
            <template v-slot:activator>
              <v-btn v-model="fab" color="mgray" dark fab small>
                <v-icon v-if="fab">
                  mdi-close
                </v-icon>
                <v-icon v-else>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <v-btn @click="enableEditing" fab dark small color="dgray">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="createDuplicate" fab dark small color="blue">
              <v-icon>mdi-content-duplicate</v-icon>
            </v-btn>
            <v-btn
              @click="deleteVacancy(vacancy)"
              fab
              dark
              small
              color="error"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-responsive>
      </v-hover>
      <v-card-actions>
        <v-btn
            v-if="!deactivated"
            @click="isApplied ? revokeApplication() : apply()"
            :dark="isApplied"
            :color="isApplied ? 'warn' : 'success'"
            :disabled="!!application && !isApplied"
            :loading="loadingApply"
            width="100%"
        >
          {{ (application && application.vacancyUUID === vacancy.uuid) ?  "Revoke Application" : "Apply" }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <VacancyCardEdit
      v-else-if="!readOnly && (edit || getvacancies.length === 0)"
      :create-new="createNew"
      @close="edit = false"
      :vacancy="vacancy"
    ></VacancyCardEdit>
    <v-card
      @click="edit = true"
      v-else
      color="white"
      class="mx-auto"
      max-width="400"
      min-height="333"
    >
      <v-responsive aspect-ratio="1">
        <v-icon size="200" class="add-icon" color="dgray"
          >mdi-plus-circle</v-icon
        >
      </v-responsive>
    </v-card>
  </div>
</template>

<script>
import VacancyCardEdit from "@/components/CreateProject/CreateVacancies/components/VacancyCardEdit";
import { mapMutations, mapGetters } from "vuex";
import application_mw from "../../../../middleware/application_mw";
import EventBus from "../../../../../event-bus";
import { v4 as uuidv4 } from "uuid";
const _ = require("lodash");

export default {
  name: "VacancyCard",
  components: { VacancyCardEdit },
  data() {
    return {
      edit: false,
      fab: false,
      loadingApply: false
    };
  },
  props: {
    vacancy: {
      type: Object,
      required: false
    },
    application: {
      default: function() {
        return {}
      }
    },
    projectUUID: {
      type: String,
      required: false
    },
    deactivated: {
      default: false
    },
    readOnly: {
      default: false
    },
    employee: {
      type: Object,
      required: false
    },
    createNew: {
      default: false
    },
    displayEmployeeOnly: {
      default: false
    }
  },
  computed: {
    ...mapGetters(["getvacancies", "getCurrentUser"]),
    employeesOrigin() {
      const country = this.vacancy.country;
      const office = this.vacancy.office;

      if (country && office) {
        return (
          this.$t("countries." + country) + ", " + this.$t("offices." + office)
        );
      }
      if (country) return this.$t("countries." + country);
      else return this.$t("vacancy.international");
    },
    isApplied() {
      return this.application.vacancyUUID && this.application.vacancyUUID === this.vacancy.uuid
    },
  },
  methods: {
    ...mapMutations(["addVacancy", "deleteVacancy"]),
    enableEditing() {
      this.edit = true;
    },
    createDuplicate() {
      const duplicate = _.cloneDeep(this.vacancy);
      duplicate.id = uuidv4();
      this.addVacancy(duplicate);
    },
    getChipColor(skill) {
      if (this.employee && this.employee.uuid) {
        if (this.employee.skills.includes(skill)) return "success darken-2";
        else return "warn";
      }
      return "msilver";
    },
    apply() {
      this.loadingApply = true
      application_mw.applyForVacancy(
          this.projectUUID,
          this.vacancy.uuid
      ). then((response) => {
        console.log(response)
        this.$emit("applied", response.data)
        this.loadingApply = false
        EventBus.$emit("SUCCESS", this.$i18n.tc("messages.applied"))
      }).catch(error => {
        this.loadingApply = false
        EventBus.$emit("ERROR", error)
      })
    },
    revokeApplication() {
      this.loadingApply = true
      application_mw.deleteApplication(this.application.uuid).then(() => {
        this.loadingApply = false
        this.$emit("applied", "")
        EventBus.$emit("SUCCESS", this.$i18n.tc("messages.applicationRevoked"))
      }).catch(error => {
        EventBus.$emit("ERROR", error)
        this.loadingApply = false
      })
    }
  }
};
</script>

<style scoped lang="scss">
.employee-name-container {
  position: absolute;
  color: $white;
  z-index: 100;
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding: 5px;
}

.employee-name {
  padding: 5px;
  border-radius: 7px;
  background: rgb(79, 68, 75, 0.4);
  mix-blend-mode: difference;
}

.skills-container {
  overflow-y: auto;
  height: 66px;
}

.sub-title {
  background-color: rgb(79, 68, 75, 0.4);
  padding: 5px;
  margin: 2px;
  border-radius: 7px;
}

.contact-text {
  font-size: $font-size-base;
  padding-left: 13px;
  font-weight: bold;
}

.skills {
  height: 100%;
}

.add-icon-card {
  position: relative;
  cursor: pointer;
}

.add-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
