import axios from "axios";
import {endpoint} from "../../config";
import store from "../store";
import user_mw from "./user_mw";
import EventBus from "../../event-bus";
const url = endpoint + 'applications/'

const idToken = store.state.account.tokenID
const accessToken = store.state.account.accessToken
const userProfile = store.state.account.profile

const config = {
    headers: { Authorization: `Bearer ${idToken}` }
};

export default {
    applyForVacancy(projectUUID, vacancyUUID) {
        const application = {
            projectUUID: projectUUID,
            vacancyUUID: vacancyUUID,
            applicantUUID: userProfile.uuid
        }

        return axios.post(url, application, config)
    },

    deleteApplication(applicationUUID) {
        const endpoint = url + applicationUUID
        return axios.delete(endpoint, config);
    },

    ownApplicationForProject(projectUUID) {
        const endpoint = url + "project/" + projectUUID + "/applicant/" + userProfile.uuid

        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    },

    getApplicationsForProject(projectUUID) {
        const endpoint = url + "project/" + projectUUID
        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res.map(application => {
                    return application.applicantUUID
                }))
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    },

    async getOwnApplications() {
        console.log(userProfile)
        const endpoint = url + "user/" + userProfile.uuid
        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res.map(application => {
                    return application.projectUUID
                }))
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    },

    async getApplicantsImagesForProject(projectUUID) {
        const endpoint = url + "project/" + projectUUID
        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(Promise.all(res.map(application => {
                    return user_mw.getUserImage(application.applicantUUID, accessToken)
                })))
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    },

    async getApplicationsForVacancy(vacancyUUID) {
        const endpoint = url + "vacancy/" + vacancyUUID
        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                const appliedUsers = res.map(async application => {
                    return await user_mw.getUser(application.applicantUUID)
                })

                resolve(Promise.all(appliedUsers))
            }).catch(error => {
                EventBus.$emit("ERROR", error)
                reject(error)
            })
        })
    }
}