<template>
  <div>
    <div class="image-upload">
      <v-img class="image" :src="this.userProfile.imageURL || require('@/assets/images/placeholder.png')">
      </v-img>
      <v-slide-x-transition>
        <v-file-input
            v-if="edit"
            class="image-input"
            hide-details
            @change="createImage"
            placeholder="Project Image*"
            outlined
            prepend-icon="mdi-camera"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="primary">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-slide-x-transition>
      <div class="pl-3">
        <v-btn @click="startEditing" v-if="!edit" color="msilver" fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <div class="save-btn" v-if="edit">
          <v-btn :loading="uploading" v-if="this.userProfile.imageURL" @click="saveImage" width="60px" height="60px" color="success" fab>
            <v-icon>mdi-content-save-move</v-icon>
          </v-btn>
          <v-icon v-if="!uploading" @click="cancelEdit" style="position: absolute" color="red" size="20px">mdi-close-circle</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import user_mw from "../../middleware/user_mw";
import EventBus from "../../../event-bus";
import {mapMutations} from "vuex";

export default {
  name: "ChangeProfilePicture",
  data () {
    return {
      edit: false,
      uploading: false,
      userProfileBackup: {}
    }
  },
  props: {
    userProfile: {
      type: Object,
      required: true
    },
    loading: {
      default: false
    }
  },
  methods: {
    ...mapMutations(["setProfile"]),
    createImage(file) {
      this.userProfileBackup = Object.assign({}, this.userProfile)

      const reader = new FileReader();
      reader.onload = (e) => {
        this.userProfile.imageURL = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    startEditing() {
      this.edit = true
    },
    cancelEdit() {
      this.edit = false
      this.userProfile = Object.assign({}, this.userProfileBackup)
    },
    saveImage() {
      this.uploading = true
      user_mw.updateValtechProfileImage(this.userProfile).then(updatedProfile => {
        this.uploading = false
        this.edit = false
        this.userProfile = updatedProfile
        this.setProfile(updatedProfile)
        EventBus.$emit("SUCCESS", this.$i18n.tc("messages.userProfileUpdated"))
      }).catch(error => {
        this.uploading = false
        EventBus.$emit("ERROR", error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.image-upload {
  border-radius: 5px;
  position: relative;
  display:flex;
  justify-content: flex-start;
  align-items:center;
  padding: 20px;
  margin-right: 40px;

  .image {
    height: 300px;
    max-width: 300px;
    background: $msilver;
    border-radius: $border-radius-root;
  }

  .image-input {
    margin-left: 12px;
    width: 300px;
  }
}

.save-btn {
}
</style>