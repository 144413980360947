<template>
  <ProjectList :projects="projects" :loaded="loaded"></ProjectList>
</template>

<script>

import ProjectList from "../components/ViewProject/ProjectList";
import project_mw from "../middleware/project_mw";
import EventBus from "../../event-bus";
export default {
  name: "OpenProjects",
  components: {ProjectList},
  data () {
    return {
      projects: [],
      loaded: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {

      project_mw.getAllProjects().then(response => {
        console.log(response)
        this.projects = response
        this.loaded = true
      }).catch(error =>  {
        console.log(error)
        this.loaded = true
        EventBus.$emit("ERROR", error)
      })
    }
  }
}
</script>

<style scoped>

</style>