const defaultState = {
  totalSteps: 0,
  currentStep: 1,
  stepsDone: []
};

const getters = {
  totalSteps: state => state.totalSteps,
  currentStep: state => state.currentStep,
  stepsDone: state => state.stepsDone
};

const actions = {};

const mutations = {
  setTotalSteps: (state, totalSteps) => (state.totalSteps = totalSteps),
  setCurrentStep: (state, currentStep) => (state.currentStep = currentStep),
  addToStepsDone: (state, stepDone) => state.stepsDone.push(stepDone),
  resetStepper: (state) => Object.assign(state, defaultState)
};

const state = window.sessionStorage["v1nd"]
  ? JSON.parse(window.sessionStorage["v1nd"]).stepper
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations
};
