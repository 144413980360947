<template>
  <v-row>
    <v-col cols="12">
      <div @click="expand" class="heading-divider" :class="{'deactivated': deactivated}">
        <h2 style="white-space: nowrap;; word-break: keep-all">{{ title }}</h2>
        <div class="center-all">
          <div class="divider"></div>
        </div>
        <v-icon v-if="!deactivated" class="chevron-icon" :class="{'arrow-open' : isOpen}">mdi-chevron-down</v-icon>
      </div>
      <v-expand-transition>
        <div v-if="isOpen">
          <slot></slot>
        </div>
      </v-expand-transition>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ExpansionTitle",
  data () {
    return {
      isOpen: false
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    open: {
      default: true
    },
    deactivated: {
      default: false
    }
  },
  created() {
    this.isOpen = this.open
  },
  methods: {
    expand() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<style scoped lang="scss">

.heading-divider {
  height: 30px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;

  .divider {
    height: 2px;
    width: 98%;
    background: $dsilver;
  }
}

.deactivated {
  pointer-events: none;
}

.chevron-icon {
  transition: all .4s;
}

.arrow-open {
  transform: rotateZ(-180deg);
  transform-origin: 50% 50%;
}
</style>