import i18n from "@/i18n";
import en from "@/locales/en.json";

export const employeeRoles = [
  {
    label: i18n.tc("vacancy.roles.developer"),
    value: "developer"
  },
  {
    label: i18n.tc("vacancy.roles.product_owner"),
    value: "product_owner"
  },
  {
    label: i18n.tc("vacancy.roles.scrum_master"),
    value: "scrum_master"
  },
  {
    label: i18n.tc("vacancy.roles.tester"),
    value: "tester"
  }
];

export const employeeRoleFields = {
  developer: [
    {
      label: i18n.tc("vacancy.fields.frontend"),
      value: "frontend"
    },
    {
      label: i18n.tc("vacancy.fields.backend"),
      value: "backend"
    }
  ]
};

export const employeeSkills = Object.keys(en.skills).map(key => {
  return {
    label: en.skills[key],
    value: key
  };
});

export const valtechCountries = Object.keys(en.countries).map(key => {
  return {
    label: en.countries[key],
    value: key
  };
});

export const valtechOffices = Object.keys(en.offices).map(key => {
  return {
    label: en.offices[key],
    value: key
  };
});
