
const defaultState = {
    tokenID: '',
    accessToken: '',
    profile: {
        id: ""
    }
};

const getters = {
    getCurrentUserUUID: state => state.profile.uuid ,
    getCurrentUserName: state => state.profile.displayName,
    getCurrentUser: state => state.profile,
    getTokenID: state => state.tokenID
};

const actions = {

};

const mutations = {
    setIdToken: (state, token) => (state.tokenID = token),
    setAccessToken: (state, token) => (state.accessToken = token),
    setProfile: (state, profile) => (state.profile = profile),
};

const state = window.sessionStorage["v1nd"]
    ? JSON.parse(window.sessionStorage["v1nd"]).account
    : Object.assign({}, defaultState);

export default {
    state,
    getters,
    actions,
    mutations
};
