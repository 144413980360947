<template>
  <v-card color="white" max-width="400">
    <v-img
      class="white--text align-end"
      height="200px"
      :src="require('@/assets/images/no-profile-dark.jpg')"
    >
      <v-card-title>
        <v-autocomplete
          class="pb-3"
          hide-details
          :items="employeeRoles"
          item-text="label"
          item-value="value"
          v-model="vacancyCopy.role"
          :label="$t('vacancy.role') + '*'"
          filled
          dark
        ></v-autocomplete>
        <v-autocomplete
          hide-details
          v-if="empRoleFields"
          :items="empRoleFields"
          v-model="vacancyCopy.role_field"
          item-text="label"
          item-value="value"
          :label="$t('vacancy.field')"
          filled
          dark
        ></v-autocomplete>
      </v-card-title>
    </v-img>
    <v-card-text>
      <v-row>
        <v-col :cols="6">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                v-model="vacancyCopy.startDate"
                label="Role Start"
                prepend-inner-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="vacancyCopy.startDate"
              :max="getProjectPeriod.end"
              :min="getProjectPeriod.start"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="6">
          <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                v-model="vacancyCopy.endDate"
                label="Role End"
                prepend-inner-icon="mdi-calendar"
                readonly
                filled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="vacancyCopy.endDate"
              :max="getProjectPeriod.end"
              :min="getProjectPeriod.start"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="12">
          <v-text-field
            filled
            hide-details
            v-model="vacancyCopy.hoursPerWeek"
            append-icon="mdi-alpha-h"
            label="Required Hours per Week"
          ></v-text-field>
        </v-col>
        <v-col
          :cols="
            expandOriginField ? (expandOriginField === 'country' ? 8 : 4) : 6
          "
        >
          <v-autocomplete
            hide-details
            v-on:blur="expandOriginField = ''"
            v-on:focus="expandOriginField = 'country'"
            :items="valtechCountries"
            v-model="vacancyCopy.country"
            item-text="label"
            item-value="value"
            filled
            label="Country"
          ></v-autocomplete>
        </v-col>
        <v-col
          :cols="
            expandOriginField ? (expandOriginField === 'office' ? 8 : 4) : 6
          "
        >
          <v-autocomplete
            hide-details
            v-on:blur="expandOriginField = ''"
            v-on:focus="expandOriginField = 'office'"
            :items="valtechOffices"
            v-model="vacancyCopy.office"
            item-text="label"
            item-value="value"
            filled
            label="Office"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            hide-details
            :items="employeeSkills"
            v-model="vacancyCopy.skills"
            item-text="label"
            item-value="value"
            deletable-chips
            :label="$t('vacancy.skills')"
            chips
            filled
            multiple
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="cancel" text color="error">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="!vacancyCopy.role" @click="save" color="success">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  employeeRoles,
  employeeRoleFields,
  employeeSkills,
  valtechCountries,
  valtechOffices
} from "@/common/config";
import { mapMutations, mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
const _ = require("lodash");

export default {
  name: "VacancyCardCreation",
  data() {
    return {
      employeeRoles,
      employeeSkills,
      valtechCountries,
      vacancyCopy: {},
      expandOriginField: ""
    };
  },
  props: {
    vacancy: {
      type: Object,
      required: false
    },
    createNew: {
      default: false
    }
  },
  computed: {
    ...mapGetters(["getProjectPeriod"]),
    empRoleFields() {
      return employeeRoleFields[this.vacancyCopy.role] || "";
    },
    valtechOffices() {
      if (this.vacancyCopy.country) {
        return valtechOffices.filter(obj => {
          return obj.value.split("_")[0] === this.vacancyCopy.country;
        });
      }
      return valtechOffices;
    }
  },
  created() {
    if (!this.createNew) {
      this.vacancyCopy = _.cloneDeep(this.vacancy);
    } else this.setEmptyVacancy();
  },
  methods: {
    ...mapMutations(["addVacancy", "editVacancy"]),
    save() {
      if (this.createNew) {
        this.addVacancy(this.vacancyCopy);
        this.setEmptyVacancy();
      } else {
        this.editVacancy(this.vacancyCopy);
      }
      this.$emit("close");
    },
    setEmptyVacancy() {
      this.vacancyCopy = {
        id: uuidv4(),
        skills: [],
        role: "",
        startDate: this.getProjectPeriod.start,
        endDate: this.getProjectPeriod.end,
        hoursPerWeek: ''
      };
    },
    cancel() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
.skills-box {
  height: 100%;
}
.card-actions {
  position: absolute;
  bottom: 0;
}
</style>
