import axios from "axios";
import {endpoint} from "../../config";
import store from "../store";

const urlUsers = endpoint + 'users'
const urlUserProfiles = endpoint + 'users/profile'

const idToken = store.state.account.tokenID
const accessToken = store.state.account.accessToken
const currentUser = store.getters.getCurrentUserUUID

const config = {
    headers: { Authorization: `Bearer ${idToken}` }
};

export default {
    async getUser(userUUID, at = accessToken) {
        const endpoint = urlUsers + "/" + userUUID + "?access_token=Bearer " + at

        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getUserImage(userUUID, at = accessToken) {
        const endpoint = urlUsers + '/' + userUUID + "/image" + "?access_token=Bearer " + at

        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async getCurrentUser(at) {
        const endpoint = urlUsers + "?access_token=Bearer " + at

        return new Promise((resolve, reject) => {
            axios.get(endpoint, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateValtechProfileURL(userProfile) {
        const endpoint =  urlUserProfiles + "/link/" + currentUser

        const config = {
            headers: { AccessToken: accessToken }
        };

        return new Promise((resolve, reject) => {
            axios.put(endpoint, userProfile, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    },

    async updateValtechProfileImage(userProfile) {
        const endpoint =  urlUserProfiles + "/image/" + currentUser

        const config = {
            headers: {
                AccessToken: accessToken,
                Authorization: `Bearer ${idToken}`
            }
        };

        return new Promise((resolve, reject) => {
            axios.put(endpoint, userProfile, config).then(async (response) => {
                const res = await response.data
                resolve(res)
            }).catch(error => {
                reject(error)
            })
        })
    }
}