<template>
  <div class="stepline-container">
    <div class="line"></div>
    <div v-for="index in totalSteps" :key="index">
      <div class="step-indicator">
        <transition name="fade">
          <div
            :key="1"
            v-if="isActive(index)"
            class="step-indicator-button-active"
          >
            {{ index }}
          </div>
          <div
            :key="2"
            v-else
            @click="changeCurrentStep(index)"
            :class="
              isAllowed(index)
                ? 'step-indicator-button clickable'
                : 'step-indicator-button'
            "
          >
            {{ index }}
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepsLine",
  props: {
    totalSteps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    stepsDone: {
      type: Array,
      required: true
    },
    condition: {
      default: true
    }
  },
  created() {
    window.addEventListener("keydown", this.handlePressedKey);
  },
  methods: {
    changeCurrentStep(step) {
      if (this.isAllowed(step)) {
        this.$emit("changeCurrentStep", step);
      }
    },
    isActive(index) {
      return this.currentStep === index;
    },
    isAllowed(index) {
      if (this.stepsDone.includes(index)) return true;
      return this.stepsDone.includes(index - 1);
    },
    handlePressedKey(e) {
      switch (e.key) {
        case "ArrowRight":
        case "ArrowDown":
          this.changeCurrentStep(this.currentStep + 1);
          break;
        case "ArrowLeft":
        case "ArrowUp":
          this.changeCurrentStep(this.currentStep - 1);
          break;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.step-indicator {
  padding-top: 10px;
  background: $background;
  height: 100%;
}

.step-indicator:last-child {
  padding-bottom: 10px;
}

.step-indicator-button {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 2px;
  background: $mgray;
  text-align: center;
  font: 32px $font-family-base;
}

.clickable {
  cursor: pointer;
  background: $msilver;
  color: $dgray;
}

.step-indicator-button-active {
  margin-top: 10px;
  padding: 8px;
  background: $background;
  text-align: center;
  font: bolder 14em $font-family-base;
}

.stepline-container {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line {
  border-left: 10px solid $white;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.fade-enter-active {
  transition: all, 0.3s;
}

.fade-leave-active {
  transition: all, 0.3s;
  display: none;
}

.fade-enter {
  font-size: 36px;
  background: none;
}

.fade-leave-to {
  font-size: 36px;
  background: none;
}
</style>
