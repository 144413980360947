<template>
  <div
    class="login-container"
  >
    <v-img
      class="login-logo"
      contain
      width="30%"
      :src="require('../assets/images/logo_dark_text.png')"
    >
    </v-img>
    <v-btn @click="login" x-large outlined color="success" class="mt-5" append>
      <v-icon class="mr-3">mdi-microsoft-azure</v-icon>
      LOGIN
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Login",
  methods: {
    login() {
      this.$msal.signIn()
    }
  },
  watch: {
    // "this.$msal.isAuthenticated()"() {
    //   location.reload()
    // }
  }
};
</script>

<style scoped lang="scss">
.login-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.login-logo {
  max-height: 500px;
}
</style>
