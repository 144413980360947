<template>
  <div class="stepper-container">
    <div class="stepper-line">
      <StepsLine
        @changeCurrentStep="setCurrentStep"
        :current-step="currentStep"
        :total-steps="totalSteps"
        :steps-done="stepsDone"
      ></StepsLine>
    </div>
    <div :key="currentStep" ref="slotcontainer" class="step-container">
      <slot class="step-item"></slot>
    </div>
  </div>
</template>
<script>
import StepsLine from "./components/Navigation";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Stepper",
  components: { StepsLine },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["totalSteps", "currentStep", "stepsDone"])
  },
  mounted() {
    if (!this.totalSteps || this.totalSteps.length === 0) {
      this.setTotalSteps(this.$refs.slotcontainer.children.length);
    }
  },
  methods: {
    ...mapMutations(["setTotalSteps", "setCurrentStep"])
  }
};
</script>

<style scoped lang="scss">
.stepper-container {
  position: relative;
  padding-left: $stepper-stepline-width;
  width: auto;
  overflow-y: auto;
  height: 100%;
}

.stepper-line {
  position: fixed;
  left: $general-sidenav-width;
  top: 0;
  width: $stepper-stepline-width;
  height: 100%;
}
</style>
