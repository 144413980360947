<template>
  <div class="choose-project-container">
    <v-row align-content="stretch" justify="center">
      <v-col
        cols="12"
        :key="vacancy.id"
        v-for="vacancy in projectData.vacancies"
      >
        <ChooseTeamMember :vacancy="vacancy"></ChooseTeamMember>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChooseTeamMember from "./components/ChooseTeamMember";
export default {
  name: "ChooseProject",
  components: { ChooseTeamMember },
  props: {
    projectData: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.choose-project-container {
  width: 100%;
}
</style>
