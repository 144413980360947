<template>
  <div v-show="show()">
    <template v-if="!loading">
      <div class="step-container">
        <div class="stepper-title">{{ title }}</div>
        <div class="slot-container">
          <slot></slot>
        </div>
      </div>
      <div class="step-controller">
        <v-row>
          <v-btn @click="goToPrevStep()" v-if="step !== 1" color="dsilver" text>{{
              $t("button.prev")
            }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn
              @click="goToNextStep()"
              class="button-next"
              :disabled="!condition"
              color="success"
              outlined
          >{{ buttonNextLabel }}</v-btn
          >
        </v-row>
      </div>
    </template>
    <template v-else>
      <div style="width: 300px; height: 80vh">
        <LoadingCircle></LoadingCircle>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import i18n from "../../../i18n";
import LoadingCircle from "../../core/LoadingCircle";

export default {
  name: "Step",
  components: {LoadingCircle},
  props: {
    step: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    condition: {
      default: true
    },
    loading: {
      default: false
    },
    buttonNextLabel: {
      default: i18n.t("button.next")
    }
  },
  computed: {
    ...mapGetters(["currentStep", "totalSteps"])
  },
  methods: {
    ...mapMutations(["setCurrentStep", "addToStepsDone"]),
    show() {
      return this.currentStep === this.step;
    },
    goToNextStep() {
      if (this.step !== this.totalSteps) {
        this.addToStepsDone(this.step);
        this.setCurrentStep(this.step + 1);
      } else {
        this.$emit('finished')
      }

    },
    goToPrevStep() {
      this.setCurrentStep(this.step - 1);
    }
  }
};
</script>

<style scoped lang="scss">
.stepper-title {
  display: flex;
  justify-content: center;
  font-size: $font-size-title;
}
.step-container {
  height: 100%;
  width: 100%;
  padding-bottom: 80px;
}
.slot-container {
  padding-top: 33px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
  min-height: 85vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.step-controller {
  position: fixed;
  width: calc(100% - 330px);
  bottom: 40px;
}
.button-next {
}
</style>
