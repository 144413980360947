<template>
  <ProjectList :projects="projects" :loaded="loaded"></ProjectList>
</template>

<script>

import ProjectList from "../components/ViewProject/ProjectList";
import project_mw from "../middleware/project_mw";

export default {
  name: "YourProjects",
  components: {ProjectList},
  data () {
    return {
      projects: [],
      loaded: false,
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load () {
      project_mw.getProjectsByUserUUID().then(response => {
        this.projects = response
        this.loaded = true
      }).catch(() =>  {
        this.loaded = true
      })
    }
  }
}
</script>

<style scoped>

</style>