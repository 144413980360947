import Vue from "vue";
import VueRouter from "vue-router";
import CreateProject from "@/components/CreateProject/CreateProject";
import Login from "@/views/Login";
import YourProjects from "../views/YourProjects";
import OpenProjects from "../views/OpenProjects";
import MySettings from "../views/MySettings";
import AppliedProjects from "../views/AppliedProjects";


Vue.use(VueRouter);

export const mainRoutes = [
  {
    path: "/",
    name: "OpenProjects",
    icon: "mdi-folder-open-outline",
    component: OpenProjects
  },
  {
    path: "/your_projects",
    name: "YourProjects",
    icon: "mdi-folder-multiple-outline",
    protected: ["engagement", "project_manager"],
    component: YourProjects
  },
  {
    path: "/create_projects",
    name: "CreateProject",
    icon: "mdi-folder-plus-outline",
    protected: ["engagement", "project_manager"],
    component: CreateProject
  },
  {
    path: "/applications",
    name: "Applications",
    protected: ["engagement", "project_manager"],
    icon: "mdi-file-find",
    component: AppliedProjects
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "mdi-cog",
    component: MySettings
  }
];

const systemRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  }
];

const routes = mainRoutes.concat(systemRoutes)

const router = new VueRouter({
  routes
});

export default router;
