<template>
  <v-app id="app">
    <template v-if="$msal.isAuthenticated()">
      <TheNavigation></TheNavigation>
      <div id="router-content" v-if="updateKey">
        <main>
          <div>
            <router-view />
          </div>
        </main>
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="snackbarColor"
        >
          {{ snackbarMessage }}

          <template v-slot:action="{ attrs }">
            <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </template>
    <template class="login" v-else>
      <Login></Login>
    </template>
  </v-app>
</template>

<script>
import TheNavigation from "./components/TheNavigation";
import EventBus from "../event-bus";
import Login from "./views/Login";
export default {
  name: "App",
  components: {Login, TheNavigation},
  data () {
    return {
      snackbar: false,
      snackbarColor: "info",
      snackbarMessage: "",
      updateKey: false
    }
  },
  mounted() {

    EventBus.$on("RELOAD", () => {
      setTimeout(() => {
        this.updateKey = true
      }, 1000)
    })

    EventBus.$on("ERROR", (message) => {
      this.snackbarMessage = message
      this.snackbarColor = "error"
      this.snackbar = true
    })

    EventBus.$on("SUCCESS", (message) => {
      this.snackbarMessage = message
      this.snackbarColor = "success"
      this.snackbar = true
    })
  }
};
</script>

<style lang="scss">
@import "assets/scss/main";


body {
  background: $background;
}

#app {
  font-family: $font-family-base;
  color: $text;
  background: $background;
}

#nav {
  width: $general-sidenav-width;
  height: 100%;
  position: fixed;
  left: 0;
  text-align: center;

  a {
    font-weight: bold;
    color: $msilver;

    &.router-link-exact-active {
      color: $text-link-active;
    }
  }
}

#router-content {
  padding: 20px 30px 20px 140px;
  height: 100%;
}

.login {
  padding: -20px -30px -20px -140px;
}

.logo {
  margin: 0 auto;
}
</style>
