<template>
  <div style="height: 100vh">
    <v-row v-if="loaded">
      <v-col cols="12" v-for="project in projects" :key="project.uuid">
        <ViewProject :abstract="true" :project="project"></ViewProject>
      </v-col>
    </v-row>
    <div style="height: 100%" v-else>
      <LoadingCircle></LoadingCircle>
    </div>
    <div class="center-all no-projects" v-if="loaded && projects.length === 0">
      {{$t("messages.noProjectsToShow")}}
    </div>
  </div>
</template>

<script>
import ViewProject from "./ViewProject";
import LoadingCircle from "../core/LoadingCircle";
export default {
  name: "ProjectList",
  components: {LoadingCircle, ViewProject},
  data () {
    return {
    }
  },
  props: {
    projects: {
      required: true,
      type: Array
    },
    loaded: {
      default: true
    }
  }
}
</script>

<style scoped lang="scss">

.no-projects {
  font-size: 40px;
  color: $mgray;
}

</style>