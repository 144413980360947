<template>
  <v-navigation-drawer src="@/assets/images/menu_background.png" width="330px" expand-on-hover color="accent" dark  fixed>
    <div class="logo pt-10">
      <v-img
        v-if="$route.name !== 'Login'"
        width="70%"
        max-width="166px"
        class="logo"
        :src="require('../assets/images/logo_dark.png')"
      ></v-img>
    </div>

    <v-list dense nav class="pa-3 pt-16">
      <v-list-item :to="route.path" v-for="route in mainRoutes" :key="route.name" link>
        <v-list-item-icon>
          <v-icon>{{ route.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{
            $t("routes." + route.name)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list three-line style="position: absolute; bottom: 10px">
      <v-list-item color="msilver" disabled selectable>
        <div class="user-information pl-12">
          <v-avatar size="70">
            <v-img
                :src="getCurrentUser.userProfile ? getCurrentUser.userProfile.imageURL : require('@/assets/images/no-profile-dark.jpg')"
            ></v-img>
          </v-avatar>
          <div class="user-role">{{userRole}}</div>
          <div class="user-name">{{getCurrentUser.displayName}}</div>
        </div>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-btn @click="logout()" color="red" outlined>LOGOUT</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import { mainRoutes } from "../router";
import EventBus from "../../event-bus";
import {mapGetters} from "vuex";
export default {
  name: "TheNavigation",
  data() {
    return {
      mainRoutes,
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"]),
    userRole () {
      const roles = this.$msal.data.user.idToken.roles || []
      let role = ""
      if (roles.includes("consultant")) role = this.$i18n.tc("roles.consultant") + ":"
      if (roles.includes("project_manager")) role = this.$i18n.tc("roles.project_manager") + ":"
      if (roles.includes("engagement")) role = this.$i18n.tc("roles.engagement") + ":"
      if (!role) {
        EventBus.$emit("ERROR", "No Role is assigned to you. Please Contact Help Desk.")
        role = "No Role Assigned"
      }
      return role
    }
  },
  created() {
    this.getProfileImage()
  },
  methods: {
    logout () {
      this.$msal.signOut()
      sessionStorage.clear()
    },
    async getProfileImage() {
      // const res = await this.$msal.msGraph('/me')
      //
      // if (res) {
      //   console.log(res)
      // }
    }
  }
};
</script>

<style scoped lang="scss">

.user-information {
  max-width: 300px;
  word-break: break-word;
}

.user-name {
  font-size: $font-size-biggest;

}
.user-role {
  font-weight: $font-weight-light;
  font-style: italic;
}
.user-email {
  font-weight: $font-weight-light;
}
</style>
