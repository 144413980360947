import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";


import msal from 'vue-msal'
import EventBus from "../event-bus";
import user_mw from "./middleware/user_mw";


Vue.use(msal, {
  auth: {
    //Find those information in the Azure Active Directory
    clientId: "4177acfc-951f-4702-8add-6425ffccf44c",
    authority: "https://login.microsoftonline.com/ec1ae248-6fcc-43a1-bc2b-88d4fc381227/",
    //Bei Localer Nutzung localhost + port nutzen, zusätzlich Einstellungen in Azure AD/V1ND/Authentication ändern
    redirectUri: "https://v1nd.onrender.com/#/",
    requireAuthOnInitialize: false,

    onToken:async ctx => {
      const idToken = ctx.data.idToken
      const accessToken = ctx.data.accessToken
      store.commit("setIdToken", idToken)
      store.commit("setAccessToken", accessToken)
      const profile = await user_mw.getCurrentUser(accessToken)
      store.commit("setProfile", profile)
      console.log(profile)
      EventBus.$emit("RELOAD")
    }
  },
  request: {
    scopes: [ `User.Read`, `openid`, `profile` ]
  },
  graph: {
    callAfterInit: false,
    baseUrl: "https://graph.microsoft.com/v1.0",
    // onResponse: (ctx) => {
    //   // const adProfile = ctx.data.graph.profile
    //   // store.commit("setProfile", adProfile)
    // }
  },
  cache: {
    cacheLocation: "localStorage"
  }
});

Vue.config.productionTip = false;


new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
