<template>
  <div>
    <ChangeProfilePicture :loading="loading" :user-profile="userProfile"></ChangeProfilePicture>
    <ChangeProfileAttributes :loading="loading" :user-profile="userProfile"></ChangeProfileAttributes>
  </div>
</template>

<script>
import ChangeProfileAttributes from "../components/Settings/ChangeProfileAttributes";
import {mapGetters} from "vuex";
import user_mw from "../middleware/user_mw";
import EventBus from "../../event-bus";
import ChangeProfilePicture from "../components/Settings/ChangeProfilePicture";
export default {
  name: "MySettings",
  components: {ChangeProfilePicture, ChangeProfileAttributes},
  data () {
    return {
      userProfile: {},
      edit: false,
      loading: false,
      uploading: false
    }
  },
  computed: {
    ...mapGetters(["getCurrentUserUUID"])
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      this.loading = true
      user_mw.getUser(this.getCurrentUserUUID).then(user => {
        this.loading = false
        this.userProfile = user.userProfile
      }).catch(error => {
        EventBus.$emit("ERROR", error)
      })
    }
  }

}
</script>

<style scoped>

</style>