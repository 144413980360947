<template>
  <div class="date-picker">
    <v-row>
      <v-text-field
        readonly
        class="pr-3"
        :value="selectedDates[0] || ''"
        :label="$t('label.start')"
        color="white"
        dark
        filled
      ></v-text-field>
      <v-text-field
        readonly
        class="pl-3"
        :value="selectedDates[1] || ''"
        :label="$t('label.end')"
        color="white"
        dark
        filled
      ></v-text-field>
    </v-row>
    <v-row>
      <v-date-picker
        :min="today"
        width="600px"
        light
        range
        v-model="selectedDates"
      ></v-date-picker>
    </v-row>
  </div>
</template>

<script>
let moment = require("moment");
export default {
  name: "ChooseProjectPeriod",
  data() {
    return {
      selectedDates: ["", ""],
      today: moment().format("YYYY-MM-DD")
    };
  },
  props: {
    projectPeriod: {
      type: Object,
      required: false
    }
  },
  watch: {
    selectedDates(newVal) {
      this.projectPeriod.start = newVal[0] || "";
      this.projectPeriod.end = newVal[1] || "";
    }
  },
  created() {
    this.selectedDates = [this.projectPeriod.start, this.projectPeriod.end];
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.v-picker.theme--light.v-picker__body {
  background: #73767b;
}
</style>
