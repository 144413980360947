<template>
  <ExpansionTitle :open="true" :deactivated="true" title="Profile">
    <div class="attribute-row pt-3">
      <div class="attribute-col">
        <div class="attribute-name">
          {{$t("settings.valtechProfile")}}
        </div>
        <div class="attribute-field">
          <v-text-field :loading="loading" :disabled="!edit" hide-details light filled label="URL" v-model="userProfile.profileLink"></v-text-field>
        </div>
      </div>
      <div class="edit-col">
        <v-btn @click="startEditing" v-if="!edit" color="msilver" fab>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <div v-if="edit">
          <v-btn @click="saveValtechProfile" width="60px" height="60px" color="success" fab>
            <v-icon>mdi-content-save-move</v-icon>
          </v-btn>
          <v-icon @click="cancelEdit" style="position: absolute" color="red" size="20px">mdi-close-circle</v-icon>
        </div>
      </div>
    </div>
  </ExpansionTitle>
</template>

<script>
import ExpansionTitle from "../core/ExpansionTitle";
import user_mw from "../../middleware/user_mw";
import {mapGetters} from "vuex";
import EventBus from "../../../event-bus";

export default {
  name: "ChangeProfileAttributes",
  components: {ExpansionTitle},
  data () {
    return {
      userProfileBackup: "",
      edit: false,
      uploading: false
    }
  },
  props: {
    loading: {
      default: false
    },
    userProfile: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters(["getCurrentUserUUID"])
  },
  methods: {
    saveValtechProfile() {
      this.uploading = true
      user_mw.updateValtechProfileURL(this.userProfile).then(updatedProfile => {
        this.uploading = false
        this.edit = false
        this.userProfile = updatedProfile;
        EventBus.$emit("SUCCESS", this.$i18n.tc("messages.userProfileUpdated"))
      }).catch(error => {
        this.uploading = false
        EventBus.$emit("ERROR", error)
      })
    },
    cancelEdit() {
      this.edit = false
      this.userProfile = Object.assign({}, this.userProfileBackup)
    },
    startEditing() {
      this.userProfileBackup = Object.assign({}, this.userProfile)
      this.edit = true
    }
  }
}
</script>

<style scoped lang="scss">

.attribute-row {
  display: flex;
  justify-content: space-between;
}

.attribute-col {
  background: $msilver;
  border-radius: $border-radius-root;
  color: $dgray;
  padding: 20px;
  margin: 12px;
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.edit-col {
  width: 5%;
  display:flex;
  justify-content:center;
  align-items:center;
}

.attribute-name {
  font-weight: $font-weight-bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attribute-field {
  width: 300px;
}

</style>