import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        dgray: "#393E46",
        mgray: "#73767B",
        dsilver: "#ACAEB0",
        msilver: "#C9CACC",
        white: "#EEEEEE",
        error: "#FF7171",
        info: "#2196F3",
        success: "#A9CCAD",
        warn: "#F8C073",
        primary: "#73767B",
        secondary: "#393E46",
        accent: "#393E46",
        background: "#d63b3b"
      },
      dark: {
        primary: "#EEEEEE",
        secondary: "#C9CACC",
        accent: "#393E46"
      }
    }
  }
});
