<template>
  <div>
    <ProjectList :projects="projects" :loaded="loaded"></ProjectList>
  </div>
</template>

<script>
import ProjectList from "../components/ViewProject/ProjectList";
import project_mw from "../middleware/project_mw";
import application_mw from "../middleware/application_mw";
export default {
  name: "AppliedProjects",
  components: {ProjectList},
  data () {
    return {
      projects: [],
      loaded: false,
      applications: []
    }
  },
  mounted() {
    this.load()
  },
  computed: {

  },
  methods: {
    async load () {
      const appliedProjects = await application_mw.getOwnApplications();
      this.applications = appliedProjects
      project_mw.getAllProjects().then(response => {
        this.projects = response.filter(project => appliedProjects.includes(project.uuid) )
        this.loaded = true
      }).catch(() =>  {
        this.loaded = true
      })
    }
  }
}
</script>

<style scoped>

</style>