import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import stepper from "@/store/modules/stepper";
import create_project from "@/store/modules/create_project";
import account from "./modules/account";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "v1nd",
  storage: window.sessionStorage
});

export default new Vuex.Store({
  modules: {
    create_project,
    stepper,
    account
  },
  plugins: [vuexPersist.plugin]
});
