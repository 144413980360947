import Vue from "vue";

const defaultState = {
  projectPeriod: {
    start: "",
    end: ""
  },
  vacancies: [],
  image: "",
  title: "",
  customer: "",
  owner: "",
  description: "",
};

const getters = {
  getProjectData: state => state,
  getvacancies: state => state.vacancies,
  getProjectPeriod: state => state.projectPeriod
};

const actions = {
  resetProjectData({commit}) {
    console.log('resett')
    commit("loadProjectData", defaultState)
    commit("resetStepper")
  }
};

const mutations = {
  addVacancy: (state, vacancy) => state.vacancies.push(vacancy),
  deleteVacancy: (state, vacancy) => {
    const index = state.vacancies.findIndex(
      item => item.id === vacancy.id
    );
    state.vacancies.splice(index, 1);
  },
  editVacancy: (state, vacancy) => {
    const index = state.vacancies.findIndex(
      item => item.id === vacancy.id
    );
    Vue.set(state.vacancies, index, vacancy);
  },
  loadProjectData: (state, project) => {
    console.log('mutate')
    Object.assign(state, project)
  }
};

const state = window.sessionStorage["v1nd"]
  ? JSON.parse(window.sessionStorage["v1nd"]).project
  : Object.assign({}, defaultState);

export default {
  state,
  getters,
  actions,
  mutations
};
