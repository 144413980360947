<template>
  <v-progress-circular
      size="200"
      class="progress-circle"
      :width="5"
      color="msilver"
      indeterminate
  ></v-progress-circular>
</template>

<script>
export default {
  name: "LoadingCircle"
}
</script>

<style scoped lang="scss">

.progress-circle {
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  position: absolute;
}
</style>